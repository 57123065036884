@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100%3B400%3B500%3B700%3B900&display=swap');

$font-family-sans-serif: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-family-monospace: 'Menlo', 'Monaco', 'Consolas', 'Lucida Console', 'Courier New', monospace;

html, body {
  font-family: $font-family-sans-serif;
  font-variant-ligatures: none;
}
