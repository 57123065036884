@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .screen-only {
    display: none;
  }

  html, body {
    background: #fff;
    color: #000;
  }

  .background {
    display: none;
  }

  main > section {
    background: none;
    width: 100vw;
    left: 0;
  }

  .logo {
    filter: none;
    background-image: url('https://www.quantum.security/assets/img/quantum-logo-for-white.svg');
    background-size: 100%;
  }

  .footer {
    display: none;
  }
}
