.questionnaire {
  .progress {
    margin-bottom: 3.5rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
      margin-bottom: 2rem;
    }

    .info {
      width: 100%;
      display: flex;
      margin-bottom: 1rem;

      .status, .category {
        flex: 1 1 50%;
        font-size: clamp(0.875rem, 1.25vw, 1rem);
      }

      .category {
        text-align: right;
      }
    }
  }

  h1.text {
    padding: 0 1rem;
    font-size: clamp(1.5rem, 1.75vw, 2rem);

    @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
      padding: 0;
    }
  }

  .description p {
    line-height: 2rem;
    color: #ccc;
    margin-bottom: 2.75rem;
    padding: 1rem 1rem 0;
    font-size: clamp(0.875rem, 2vw, 1.25rem);

    @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
      line-height: 1.5;
      margin-bottom: 1rem;
      padding: 1rem 0 0;
    }
  }

  .answer {
    display: flex;
    background: rgba(0, 0, 0, 0.125);
    padding: 2rem 1rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
      display: block;
     }

    .response {
      flex: 1 1 20%;
      text-align: center;

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        display: flex;
        justify-content: space-between
      }

      .label {
        display: block;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;

        @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
          font-size: .75rem;
          height: 34px;
        }
      }

      .mat-radio-label-content {
        display: none;
      }
    }
  }

  .controls {
    display: flex;
    border-top: solid 1px #333;
    padding-top: 1rem;
    margin-top: 3.5rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
      margin-top: 0;
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
      .mat-raised-button {
        max-width: 100px;
      }
    }

    .previous, .next {
      flex: 1 1 50%;
    }

    .next {
      text-align: right;
    }
  }
}
