.scores-wrapper {
  background: rgba(0, 0, 0, 0.1);
  margin: -3.5rem -5rem 0;
  padding: 1rem 5rem;

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    display: none;
  }
}

.scores {
  display: flex;
  align-items: center;
  margin-top: 2rem;

  & > qs-score {
    flex: 1 1 33%;
    text-align: center;
    position: relative;
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    flex-direction: column;
  }

}

.legend {
  margin: 2rem auto 2rem;
  width: 20rem;
  height: 2rem;
  text-align: center;
  border: solid 1px #333;
  border-radius: 1rem;
  padding: 0;

  & > li {
    display: inline-flex;
    margin: 0.5rem 0.75rem;
    font-size: 0.8em;

    & > .tile {
      background: #666;
      display: inline-block;
      height: 0.8rem;
      width: 0.8rem;
      margin-right: 0.5rem;
      border-radius: 0.25rem;
    }

    &.danger .tile {
      background: #f93939;
    }

    &.warning .tile {
      background: #ffc800;
    }

    &.success .tile {
      background: #00da55;
    }
  }
}

.mat-expansion-panel {
  background-color: #333;
}

.mat-expansion-panel-header {
  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    height: auto;
    min-height: 70px;
    padding: 5px 24px !important;
  }
}

.mat-expansion-indicator {
  &::after {
    margin-top: -5px;
  }
}

@media print {
  .scores-wrapper {
    background: #f1f1f1;
  }
}

h5 {
  &.cta {
    font-size: clamp(1.5rem, 1.75vw, 2rem);
    font-weight: normal;
    margin: 0 auto 4rem;
    text-align: center;
  }
}
