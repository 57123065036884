.splash {
  padding: 9rem 4.5rem;

  @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    padding: 6.5rem 3rem;
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    padding: 4.5rem 2rem;
  }

  h1 {
    color: #fff;
    font-weight: 600;
    font-size: 2.5em;
    font-size: clamp(2rem, 2.5vw, 2.5rem);

    .subtitle {
      display: block;
      font-size: 1.5rem;
      font-weight: 400;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      font-size: clamp(1.15rem, 2.5vw, 1.5rem);

      @media screen and (max-width: 810px) {
        line-height: 1.5;
      }
    }
  }

  ul {

    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
      padding-left: 1rem;
    }

    li {
      color: #999;
      line-height: 1.75em;
      margin-bottom: 0.75rem;
      font-size: clamp(0.875rem, 2.5vw, 1rem);

      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        line-height: 1.5;
      }
    }
  }

  h3 {
    color: #ccc;
    margin-top: 7rem;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 2.5vw, 1.125rem);

    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
      margin-top: 3.5rem;
    }
  }
}
